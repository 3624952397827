<template>
    <vs-card>
        <div slot="header" class="w-full items-center flex">
                <h4 class="capitalize">
                    {{ this.component.module }}
                </h4>
            <vs-button
                class="ml-auto"
                icon="close"
                size="small"
                @click="$emit('removeComponent', index)"
            ></vs-button>
        </div>
        <div class=" flex justify-between gap-8">
            <div class=" w-1/2">
                <vs-input
                    label="CTA link"
                    class="mt-3 w-full"
                    v-model="JSONData[moduleIndex].elements[index].cta_link"
                />
                <div class="w-full mt-3">
                    <label class="text-base font-semibold">Desktop Image 1280x220</label>
                    <div>
                        <img v-if="JSONData[moduleIndex].elements[index].image" :src="JSONData[moduleIndex].elements[index].image" alt class="img-fluid" />
                        <div v-else id="desktop" class=" text-center relative" key="asdf">
                            <div
                                class="border-2 border-dashed border-gray-500 p-5 
                                h-full w-full flex flex-col gap-3 
                                items-center justify-center"
                                @dragover.prevent
                                @drop="handleDrop($event, 'desktop')"
                            >
                                <feather-icon icon="UploadIcon" class="text-primary2" />
                                <p class="text-primary2 text-xl">Drop files to upload</p>
                                <p class="text-primary2 text-lg">or</p>
                                <input type="file" ref="fileInputDesktop" hidden @change="handleFileChange($event, 'desktop')" accept="image/*" />
                                <vs-button @click="uploadDesktopImage" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
                                <!-- <p class="text-lg font-light">Recommended Image Size</p>
                                <p class="text-lg font-light">1280 x 220</p> -->
                            </div>
                        </div>
                        <vs-button v-if="JSONData[moduleIndex].elements[index].image" class="my-2" @click="removeDesktopImage">Remove desktop image</vs-button>
                    </div>
                </div>
                <div class="w-full mt-3">
                    <label class="text-base font-semibold">Mobile Image 360x220</label>
                    <div>
                        <img v-if="JSONData[moduleIndex].elements[index].image_mobile" :src="JSONData[moduleIndex].elements[index].image_mobile" alt class="img-fluid" />
                        <div v-else id="mobile" class=" text-center relative" key="asdf">
                            <div
                                class="border-2 border-dashed border-gray-500 p-5 
                                h-full w-full flex flex-col gap-3 
                                items-center justify-center"
                                @dragover.prevent
                                @drop="handleDrop($event, 'mobile')"
                            >
                                <feather-icon icon="UploadIcon" class="text-primary2" />
                                <p class="text-primary2 text-xl">Drop files to upload</p>
                                <p class="text-primary2 text-lg">or</p>
                                <input type="file" ref="fileInputMobile" hidden @change="handleFileChange($event, 'mobile')" accept="image/*" />
                                <vs-button @click="uploadMobileImage" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
                                <!-- <p class="text-lg font-light">Recommended Image Size</p>
                                <p class="text-lg font-light">1280 x 220</p> -->
                            </div>
                        </div>
                        <vs-button v-if="JSONData[moduleIndex].elements[index].image_mobile" class="my-2" @click="removeMobileImage">Remove mobile image</vs-button>
                    </div>
                </div>
            </div>

        </div>
        <div class=" mt-5">
            <legend>The square brackets <code>[ ]</code> in the URL, such as <code>[nurse]</code>, are placeholders that will be automatically replaced with the appropriate user type (e.g., <code>nurse</code>, <code>doctor</code>) when processed.</legend>
        </div>
    </vs-card>
</template>

<script>
import axios from "@/axios";
export default {
    components: {
    },
    data() {
        return {
            imageType: '',
        };
    },
    props: ["component", "index", "moduleIndex", "JSONData"],
    methods: {
        handleFileChange(event, type) {
            this.imageType = type;
            this.uploadImage(event.target.files[0]);
        },
        removeDesktopImage() {
            this.JSONData[this.moduleIndex].elements[this.index].image = '';
        },
        removeMobileImage() {
            this.JSONData[this.moduleIndex].elements[this.index].image_mobile = '';
        },
        uploadDesktopImage() {
            this.$refs.fileInputDesktop.click();
        },
        uploadMobileImage() {
            this.$refs.fileInputMobile.click();
        },
        handleDrop(event, type) {
            event.preventDefault();
            this.imageType = type;
            this.uploadImage(event.dataTransfer.files[0]);
        },
        uploadImage(event, key = "profileImage") {
            this.$vs.loading({
                text: "Uploading...",
                color: "#3dc9b3",
            });
            let self = this;
            let data = new FormData();
            data.append("profileImage", event);
            let config = {
                header: {
                    "Content-Type": "image/png",
                },
            };
        axios
            .post("/api/v1/doctors/upload?key=" + key, data, config)
            .then((res) => {
                if (this.imageType === "desktop") {
                    this.JSONData[this.moduleIndex].elements[this.index].image = res.data.Location;
                    // self.data.desktopImage = res.data.Location;
                    // this.desktopImageUrl = res.data.Location;
                } else if (this.imageType === "mobile") {
                    this.JSONData[this.moduleIndex].elements[this.index].image_mobile = res.data.Location;
                    // self.data.mobileImage = res.data.Location;
                    // this.mobileImageUrl = res.data.Location;
                }
                this.$vs.loading.close();
            })
            .catch((err) => {
                this.$vs.loading.close();
                if (err && err.status === 415) {
                    this.$vs.notify({
                        title: "Upload Failed",
                        text: "File Format Not Supported",
                        color: "danger",
                    });
                }   else {
                    console.log(err)
                    this.$vs.notify({
                      title: "Upload Failed",
                      text: "Please try again later.",
                      color: "danger",
                    });
                }
            });
        },
    },
};
</script>

<style>
label.image-upload {
  border: 1px solid rgba(40, 199, 111, 1);
  color: rgba(40, 199, 111, 1);
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
}
</style>
  