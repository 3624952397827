<template>
  <vs-card>
    <div slot="header" class="w-full items-center flex">
      <h4 class="capitalize">
        {{ this.component.module }}
      </h4>
      <vs-button
        class="ml-auto"
        icon="close"
        size="small"
        @click="$emit('removeComponent', index)"
      ></vs-button>
    </div>
    <div v-show="!isDragging">
      <!-- <vs-input
        label="Title"
        class="mt-3 md:w-1/2 w-full"
        @input="handleTitleChange($event)"
        :value="JSONData[index].title"
      />
      <vs-input
        label="Description"
        class="mt-3 md:w-1/2 w-full"
        @input="handleDescriptionChange($event)"
        :value="JSONData[index].description"
      /> -->
      <label class="text-sm ml-1 mt-3">Video</label>

      <!-- <video
        v-if="JSONData[index].source"
        class="w-full max-w-full"
        height="480"
        controls
        preload="auto"
        style="border-radius: 8px"
      >
        <source :src="JSONData[index].source" />
      </video> -->
      <iframe v-if="JSONData[index].source" width="100%" height="480" :src="JSONData[index].source" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <vs-button
        v-if="JSONData[index].source"
        class="bg-primary2 my-2"
        @click="handleRemoveSource"
        >Remove Video</vs-button
      >
      <div v-else>
        <input
          type="file"
          @change="handleFileChange($event)"
          accept="video/*"
        />
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  props: ["component", "index", "JSONData", "isDragging"],
  methods: {
    ...mapActions("eCommerce", ["uploadMedia"]),

    handleFileChange(event) {
      this.uploadVideo(event.target.files[0]);
    },
    async uploadVideo(event) {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
      });
      let data = new FormData();
      data.append("videoSource", event);
      try {
        const res = await this.uploadMedia(data);
        this.$emit("handleVideoSourceChange",res.data[0].Location,this.index)
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.notify({
          title: "Failed to upload video.",
          text: "Please try again later.",
          color: "danger",
        });
        this.$vs.loading.close();
      }
    },
    async handleRemoveSource() {
      this.JSONData[this.index].source = "";
    },
    handleTitleChange(value) {
      this.$emit('handleVideoTitleChange',value,this.index)
    },
    handleDescriptionChange(value) {
      this.$emit('handleVideoDescriptionChange',value,this.index)
    }
  },
};
</script>
