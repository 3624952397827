<template>
    <vs-card>
        <div slot="header" class="w-full items-center flex justify-between">
            <h4 class="capitalize">
                {{ this.component.module }}
            </h4>
            <div class=" flex items-center">
                <vs-button
                    class="mr-2 "
                    size="medium"
                    @click="addSlide"
                >Add Slide</vs-button>
                <vs-button
                    class=""
                    icon="close"
                    size="medium"
                    @click="$emit('removeComponent', moduleIndex)"
                    ></vs-button>
                </div>    
        </div>
        <div v-show="!isDragging">
            <draggable
              v-model="JSONData[moduleIndex].elements"
              v-bind="dragOptions"
            >
            <div v-for="slide, carouselIndex in JSONData[moduleIndex].elements" :key="carouselIndex">
                <carouselForm :component="slide" :JSONData="JSONData" :moduleIndex="moduleIndex" :index='carouselIndex' @removeComponent="removeSlide" />
            </div>
            </draggable>
        </div>
    </vs-card>
</template>

<script>
import carouselForm from  "./carouselForm.vue";
import draggable from "vuedraggable";

export default {
    components: {
        carouselForm,
        draggable,
    },
    data() {
        return {
        };
    },
    props: ["component", "moduleIndex", "JSONData", "isDragging"],
    methods: {
        addSlide() {
            if(this.JSONData[this.moduleIndex].elements.length < 4){
                this.JSONData[this.moduleIndex].elements.push({
                    cta: '',
                    image: '',
                    image_mobile: ''
                })
            }
        },
        removeSlide(index) {
            this.JSONData[this.moduleIndex].elements.splice(index, 1)
        },
    },
    computed: {
      dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
    },
    
};
</script>
<style scoped>
.ghost {
  opacity: 0.5;
}
</style>
  