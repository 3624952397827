<template>
  <vs-card>
    <div slot="header" class="w-full items-center flex justify-between">
      <h4 class="capitalize">
        {{ this.component.module }}
      </h4>
      <div class="flex items-center">
        <vs-button class="mr-2" size="medium" @click="addBanner"
          >Add Banner</vs-button
        >
        <vs-button
          class=""
          icon="close"
          size="medium"
          @click="$emit('removeComponent', moduleIndex)"
        ></vs-button>
      </div>
    </div>
    <div v-show="!isDragging">
      <draggable v-model="JSONData[moduleIndex].elements" v-bind="dragOptions">
        <div
          v-for="(banner, bannerIndex) in JSONData[moduleIndex].elements"
          :key="bannerIndex"
        >
          <bannerForm
            :component="banner"
            :JSONData="JSONData"
            :moduleIndex="moduleIndex"
            :index="bannerIndex"
            @removeComponent="removeBanner"
            @bannerFormValid="handleFormValid"
            @bannerFormInvalid="handleFormInvalid"
            ref="bannerForm"
          />
        </div>
      </draggable>
    </div>
  </vs-card>
</template>

<script>
import bannerForm from "./bannerForm.vue";
import draggable from "vuedraggable";

export default {
  components: {
    bannerForm,
    draggable,
  },
  data() {
    return {
    };
  },
  props: ["component", "moduleIndex", "JSONData", "isDragging"],
  methods: {
    addBanner() {
      if (this.JSONData[this.moduleIndex].elements.length < 4) {
        this.JSONData[this.moduleIndex].elements.push({
          title: "",
          description: "",
          cta_title: "",
          cta_link: "",
          image: "",
          image_mobile: "",
        });
      }
    },
    removeBanner(index) {
      this.JSONData[this.moduleIndex].elements.splice(index, 1);
    },
    handleFormValid(value) {
      this.$emit('isBannerFormValid', value);
    },
    handleFormInvalid(value) {
      this.$emit('isBannerFormValid', value);
    },
    handleValidateBannerForm() {
      if (this.$refs.bannerForm) {
        this.$refs.bannerForm.forEach(component => {
          if (component.validateFile) {
            component.validateFile();
          }
        });
      }
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.5;
}
</style>
