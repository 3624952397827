<template>
  <vs-card>
    <div slot="header" class="w-full items-center flex">
      <h4 class="capitalize">
        {{ this.component.module }}
      </h4>
      <vs-button
        class="ml-auto"
        icon="close"
        size="small"
        @click="$emit('removeComponent', index)"
      ></vs-button>
    </div>
    <div v-show="!isDragging">
      <vs-input
        label="Title"
        class="my-3 md:w-1/2 w-full"
        @input="handleTitleChange($event)"
        :value="JSONData[index].title"
        
      >
      </vs-input>
      <multiselect
        v-model="selectedProducts"
        :options="options"
        class="md:w-1/2 w-full"
        :multiple="true"
        :searchable="true"
        trackBy="_id"
        label="name"
        :loading="isLoading"
        :closeOnSelect="false"
        :hideSelected="true"
        placeholder="Select Product"
      />
      <!-- <builder-products :product_ids="data.popular_product" /> -->
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  props: ["component", "index", "JSONData", "isDragging"],
  data: function() {
    return {
      options: [],
      isLoading: false,
      selectedProducts: [],
      fetchProductParams: {
        search: "",
        sort: "_id",
        dir: "desc",
        page: 1,
        limit: 9999999999,
      },
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    ...mapActions("storeProducts", ["fetchProducts"]),

    handleTitleChange(value) {
      this.$emit("handleProductsTitleChange", value, this.index);
    },
    handleSelectProduct(value) {
      this.$emit("handleSelectProduct", value, this.index);
    },
  },
  async created() {
    this.isLoading = true;
    this.selectedProducts = this.JSONData[this.index].elements;
    this.fetchProducts(this.fetchProductParams)
      .then((res) => {
        this.options = [...res.data.data.docs]
          .map((product) => ({
            _id: product._id,
            name: product.name,
            hideStatus: product.hideStatus,
          }))
          .filter((v) => v.hideStatus === false);
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  updated: function () {
    this.selectedProducts = this.JSONData[this.index].elements;
  },
  watch: {
    selectedProducts: function (newData, oldData) {
      this.handleSelectProduct(newData);
    },
  },
};
</script>
